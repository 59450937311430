// -----------------------------------------------------------------/------------------------------------------------------------------/
// question types helper
// -----------------------------------------------------------------/------------------------------------------------------------------/

export class QuestionTypesHelper {
    
    static get QUESTION_TYPES(): any {
        return {
            checkbox: 'checkbox',
            dropdown: 'dropdown',
            input: 'input',
            inputText: 'input_text',
            inputNumber: 'input_number',
            range: 'range',
            radio: 'radio',
            datetime: 'datetime',
            textarea: 'textarea',
            inputPair: 'double_input',
            multiSelect: 'multi_select',
            twoInput: 'two_input',
            threeInput: 'three_input',
            historyDiagnoses: 'history_diagnoses',
            baselineVitalSigns: 'baseline_vital_signs',
            baselineVitalSignsBloodPressure: 'baseline_vital_signs_blood_pressure'
        };
    } // get questionTypes

} // end QuestionTypesHelper
// -----------------------------------------------------------------/------------------------------------------------------------------/