// -----------------------------------------------------------------/------------------------------------------------------------------/
// user service
// -----------------------------------------------------------------/------------------------------------------------------------------/

// modules
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

// services
import { HttpBaseService } from '../http-base/http-base.service';
import { AuthService } from '../auth/auth.service';

// models
import { User } from '../../models/classes/user';

@Injectable({
  providedIn: 'root'
})
export class UserService extends HttpBaseService<User> {

  constructor(public _http: HttpClient) {
    super(`${environment.nodeServerBaseUrl}/api/AppUsers`, _http);
  } // end constructor


  getUser(userId: string): Observable<User> {
    return this.get(`/${userId}`);
  } // end getUser


  updateUser(userId: string, user: User) {
    return this.patch(`/${userId}`, user);
    // return this.patch(`/${userId}`, user);

  } // end updateUser


  getUserListWithRoles(): Observable<User[]> {
    const params = { 'filter': JSON.stringify({ 'include': ['roles'], 'where': { 'isActive': true } }) };
    return this.get<User[]>('', params);
  } // end getUsers


  getUserWithRoles(id: string): Observable<User[]> {
    const params = { 'filter': JSON.stringify({ 'include': ['roles'], 'where': { 'id': id } }) };
    return this.get<User[]>('', params);
  } // end getUsers


  deactivateUser(userId: string, modal: Object) {
    return this.patch('/' + userId, modal);
  } // end deactivateUser


  /**
   * @description Gets an array of users with a specific role
   * @returns Observable<User[]>
   */
  private _getUsersByRole(role: string) {
    return this.get<User[]>('/findByRole/' + role);
  } // end _getUsersByRole

  
  /**
   * @description Gets an array of physicians
   * @returns Observable<User[]>
   */
  getPhysicians(): Observable<User[]> {
    return this._getUsersByRole('physician');
  } // end getPhysicians

} // end UserService
// -----------------------------------------------------------------/------------------------------------------------------------------/
