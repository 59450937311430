// -----------------------------------------------------------------/------------------------------------------------------------------/
// routes helper
// -----------------------------------------------------------------/------------------------------------------------------------------/

export class RoutesHelper {

  static get HOME() { return '/home'; }
  static get LOGIN() { return '/login'; }
  static get FORGOT_PASSWORD() { return '/forgot-password'; }
  static get RESET_PASSWORD() { return '/reset-password'; }
  static get RESET_PASSWORD_CONFIRMATION() { return '/reset-password-confirmation'; }
  static get FORGOT_PASSWORD_CONFIRMATION() { return '/forgot-password-confirmation'; }

  static get USER_MANAGEMENT() { return '/user-management'; }
  static get ASSESMENT() { return '/assessment'; }
  static get NURSE_ASSESSMENTS() { return '/nurse-assessments'; }
  static get PHYSICIAN_ASSESSMENTS() { return '/physician-assessments'; }
  static get ADMIN_ASSESSMENTS() { return '/admin-assessments'; }
  static get NURSE_HOME() { return '/nurse-home'; }
  static get COMPLETED_ASSESSMENT() { return '/completed-assessment/'; }
  static get ASSESSMENT_DETAILS() { return '/assessment-details/'; }
  static get EXPORT_DATA() { return '/export-data';}
  static get NEW_ASSESSMENT() { return '/new-assessment/'; }
  
  // * these need to be custom due to there are query parameters that aren't located to the end of the url
  static getNewAssessmentChild(rootAssessmentId: string, childAssessmentId: string) { return '/new-assessment/' + rootAssessmentId + '/assessment/' + childAssessmentId }
  static getNewAssessmentPreview(rootAssessmentId: string) { return '/new-assessment/' + rootAssessmentId + '/preview'; }
  static getNewAssessmentSendToPhysician(rootAssessmentId: string) { return '/new-assessment/' + rootAssessmentId + '/send'; }  

} // end RoutesHelper
// -----------------------------------------------------------------/------------------------------------------------------------------/
