// -----------------------------------------------------------------/------------------------------------------------------------------/
// assessment model
// -----------------------------------------------------------------/------------------------------------------------------------------/

import { IAssessment } from '../interfaces/iassessment';
import { Base } from './base';
import { QuestionOptionSelected } from './question-option-selected';
import { AssessmentType } from './assessment-type';
import { User } from './user';

export class Assessment extends Base implements IAssessment {

  assessmentTypeId: number;
  parentAssessmentId: number;
  nurseId: number;
  physicianId: number;
  name: string;
  status: string;
  residentName: string;
  residentLastName: string;
  residentFirstName: string;
  residentAge: string;
  residentGender: string;

  assessmentType: AssessmentType;
  questionOptionSelecteds: QuestionOptionSelected[];
  assessmentQuestionMappingList: any[];
  nurse: User;
  physician: User;
  childAssessments: Assessment[];
  messageThreadId: number;

  constructor() {
    super();
  } // end constructor


  /**
   *
   * @param nurseId {number} the id of the nurse conducting the assessment
   * @param assessmentTypeId  {number} the assessment type id
   * @param status {string} the status could be 'in_progress' or 'completed'
   * @param createdBy {string} the id of the user creating the assessment
   * @param isActive? {boolean}  default value is set to true
   */
  static onCreate(nurseId: number, assessmentTypeId: number, status: string, createdBy: string, isActive?: boolean): Assessment {
    const model = new Assessment()._onCreate(isActive || true, createdBy);

    model.nurseId = nurseId;
    model.assessmentTypeId = assessmentTypeId;
    model.status = status;

    return model;
  } // end onCreate

  /**
     * @description it returns an assessment object with an the update values populated
     *
     * @param updatedBy {string} the id of the nurse completing the assessment
     */
  static onUpdate(updatedBy: string): Assessment {
    return new Assessment()._onUpdate(updatedBy);
  } // end onCompleted


  /**
   * @description it returns an assessment object with a status of 'completed'
   *
   * @param updatedBy {string} the id of the nurse completing the assessment
   */
  static onCompleted(updatedBy: string): Assessment {
    const model = new Assessment()._onUpdate(updatedBy);

    model.status = 'completed';

    return model;
  } // end onCompleted


  /**
 * @description it returns an assessment object with a status of 'canceled'
 *
 * @param updatedBy {string} the id of the nurse completing the assessment
 */
  static onCanceled(updatedBy: string): Assessment {
    const model = new Assessment()._onUpdate(updatedBy);

    model.status = 'canceled';

    return model;
  } // end onCanceled


} // end Assessment
// -----------------------------------------------------------------/------------------------------------------------------------------/
