// -----------------------------------------------------------------/------------------------------------------------------------------/
// assessment service
// -----------------------------------------------------------------/------------------------------------------------------------------/

// modules
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpBaseService } from '../http-base/http-base.service';
import { Observable } from 'rxjs';

// models
import { AssessmentType } from '../../models/classes/assessment-type';

@Injectable({
  providedIn: 'root'
})
export class AssessmentTypeService extends HttpBaseService<AssessmentType> {

  constructor(public _http: HttpClient) {
    super(`${environment.nodeServerBaseUrl}/api/AssessmentTypes`, _http);
  } // end constructor


  /**
   * @param type? {string}  type is an optional parameter to get specific type of assessment, i.e, Urinary Tract Infection Assessment
   */
  getAssessmentType(type?: string): Observable<AssessmentType[]> {
    type = type || 'Assessment';
    const params = { 'filter': JSON.stringify({ 'where': { 'name': type }, 'include': { 'sections': [{ 'questions': ['questionType', 'questionOptions'] }] } }) };

    return this.get<AssessmentType[]>('', params);
  } // end getAssessmentType

  getAssessmentTypeById(id: string): Observable<AssessmentType[]> {
    const params = { 'filter': JSON.stringify({ 'where': { 'assessmentTypeId': id }, 'include': { 'sections': [{ 'questions': ['questionType', 'questionOptions'] }] } }) };

    return this.get<AssessmentType[]>('', params);
  } // end getAssessmentType
}
