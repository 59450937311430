// -------------------------------------------------------------------------/-------------------------------------------------------------------------/
// spinner service
// -------------------------------------------------------------------------/-------------------------------------------------------------------------/

// modules
import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

// services
import { NgxSpinnerService } from 'ngx-spinner';

// -------------------------------------------------------------------------/-------------------------------------------------------------------------/
@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private textSubject: BehaviorSubject<string>;
  private textDefault: string;
  text$: Observable<string>;

  private sizeSubject: BehaviorSubject<string>;
  private sizeDefault: string;
  size$: Observable<string>;

  private colorSubject: BehaviorSubject<string>;
  private colorDefault: string;
  color$: Observable<string>;

  private typeSubject: BehaviorSubject<string>;
  private typeDefault: string;
  type$: Observable<string>;

  private bdOpacitySubject: BehaviorSubject<string>;
  private bdOpacityDefault: string;
  bdOpacity$: Observable<string>;

  private bdColorSubject: BehaviorSubject<string>;
  private bdColorDefault: string;
  bdColor$: Observable<string>;

  constructor(private ngxSpinnerService: NgxSpinnerService) {
    this.textDefault = 'Loading ...';
    this.sizeDefault = 'medium';
    this.colorDefault = '#3880ff';
    this.typeDefault = 'ball-scale-pulse';      // * if you want to see what type are availbale, check this site: https://napster2210.github.io/ngx-spinner/
    this.bdOpacityDefault = '0.5';
    this.bdColorDefault = 'rgba(250,250,250,0.4)';

    this.textSubject = new BehaviorSubject<string>(this.textDefault);
    this.sizeSubject = new BehaviorSubject<string>(this.sizeDefault);
    this.colorSubject = new BehaviorSubject<string>(this.colorDefault);
    this.typeSubject = new BehaviorSubject<string>(this.typeDefault);
    this.bdOpacitySubject = new BehaviorSubject<string>(this.bdOpacityDefault);
    this.bdColorSubject = new BehaviorSubject<string>(this.bdColorDefault);

    this.text$ = this.textSubject.asObservable();
    this.size$ = this.sizeSubject.asObservable();
    this.color$ = this.colorSubject.asObservable();
    this.type$ = this.typeSubject.asObservable();
    this.bdOpacity$ = this.bdOpacitySubject.asObservable();
    this.bdColor$ = this.bdColorSubject.asObservable();
  } // end constructor

  private set text(value: string) {
    this.textSubject.next(value);
  } // end set text

  private set size(value: string) {
    this.sizeSubject.next(value);
  } // end set size


  private set color(value: string) {
    this.colorSubject.next(value);
  } // end set color

  private set type(value: string) {
    this.typeSubject.next(value);
  } // end set type

  private set bdOpacity(value: string) {
    this.bdOpacitySubject.next(value);
  } // end set bdOpacity

  private set bdColor(value: string) {
    this.bdColorSubject.next(value);
  } // end set bdColor

  show(text?: string, size?: string, color?: string, type?: string, bdOpacity?: string, bdColor?: string) {
    // * update settings
    if (!this.isNullOrEmpty(text)) this.text = text;
    if (!this.isNullOrEmpty(size)) this.size = size;
    if (!this.isNullOrEmpty(color)) this.color = color;
    if (!this.isNullOrEmpty(type)) this.type = type;
    if (!this.isNullOrEmpty(bdOpacity)) this.bdOpacity = bdOpacity;
    if (!this.isNullOrEmpty(bdColor)) this.bdColor = bdColor;

    this.ngxSpinnerService.show();
  } // end show


  hide() {
    // * set default values again
    this.text = this.textDefault;
    this.size = this.sizeDefault;
    this.color = this.colorDefault;
    this.type = this.typeDefault;
    this.bdOpacity = this.bdOpacityDefault;
    this.bdColor = this.bdColorDefault;

    this.ngxSpinnerService.hide();
  } // end hide

  isNullOrEmpty(value: string) {
    return value === undefined || value === null || value === '';
  } // end isNullOrEmpty

} // end SpinnerService
// -------------------------------------------------------------------------/-------------------------------------------------------------------------/
