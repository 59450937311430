// -----------------------------------------------------------------/------------------------------------------------------------------/
// http base service
// -----------------------------------------------------------------/------------------------------------------------------------------/

// modules
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { NavController } from '@ionic/angular';
import { RoutesHelper } from 'src/app/helpers/routes.helper';
import { SpinnerService } from '../spinner/spinner.service';

export class HttpBaseService<T> {

  constructor(private url: string, private http: HttpClient) { }

  // tslint:disable-next-line:no-shadowed-variable
  get<T>(method: string, _params?: any): Observable<any> {
    const httpOptions = this._setRequestOptions(_params, null);

    return this.http.get(this.url + method, httpOptions)
      .pipe(
        map(response => response),
        catchError(this.handleError),
      );
  } // end  get

  post(method: string, resource: any, _params?: any): Observable<any> {
    // const httpOptions = this._setRequestOptions(null, resource);
    const httpOptions = this._setAuthorizationHeader(null, resource);
    if (_params && _params['access_token']) {
      localStorage.setItem('access-token', _params['access_token']);
    }

    return this.http.post(this.url + method + this.accessTokenParam, resource, httpOptions)
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  patch(method: string, resource: any): Observable<any> {
    // const httpOptions = this._setRequestOptions(null, resource);
    const httpOptions = this._setAuthorizationHeader(null, resource);
    return this.http.patch(this.url + method + this.accessTokenParam, resource, httpOptions)
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  put(method: string, resource: any): Observable<any> {
    //const httpOptions = this._setRequestOptions(null, resource);
    const httpOptions = this._setAuthorizationHeader(null, resource);
    return this.http.put(this.url + method + this.accessTokenParam, {}, httpOptions)
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  delete(method: string, resource?: any): Observable<any> {
    if (resource) {
      // const httpOptions = this._setRequestOptions(null, resource);
      const httpOptions = this._setAuthorizationHeader(null, resource);
      return this.http.request('delete', this.url + method + this.accessTokenParam, httpOptions)
        .pipe(
          map(response => response),
          catchError(this.handleError)
        );
    } else {
      return this.http.delete(this.url + method + this.accessTokenParam)
        .pipe(
          map(response => response),
          catchError(this.handleError)
        );
    }
  }

  private handleError(error: Response) {
    if (error.status === 400) {
      // Need to update errors
      // return ErrorObservable.create(new BadInput(error.json()));
    }

    if (error.status === 404) {
      // Need to update errors
      // return ErrorObservable.create(new NotFoundError());
    }

    if (error.status === 401) {
      // this.spinnerService.hide();
      // this.navController.navigateRoot(RoutesHelper.LOGIN);
    }

    if (error.status === 403) {
      // this.spinnerService.hide();
      // this.navController.navigateRoot(RoutesHelper.LOGIN);
    }

    return throwError(error);
    // return ErrorObservable.create(new AppError(error));
  } // end handleError


  private _setAccessTokenParam(params: any): any {
    params['access_token'] = localStorage.getItem('access-token');
    return params;
  } // end _setAccessTokenParam


  private _setRequestOptions(params?: any, body?: any): any {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('access-token') });

    return {
      headers: headers,
      params: params ? this._setAccessTokenParam(params) : this._setAccessTokenParam({}),
      body: body ? Object.assign({}, body) : {},
    };
  } // end _setRequestOptions

  private _setAuthorizationHeader(params?: any, body?: any): any {
    var headers;
    if (localStorage.getItem('access-token')) headers = new HttpHeaders({ 'Authorization': localStorage.getItem('access-token') });

    return {
      headers: headers,
      params: params ? this._setAccessTokenParam(params) : this._setAccessTokenParam({}),
      body: body ? Object.assign({}, body) : {},
    };
  } // end _setAuthorizationHeader

  get accessTokenParam() {
    const token = localStorage.getItem('access-token');

    //return token ? `?access_token=${localStorage.getItem('access-token')}` : '';
    return '';
  } // end accessTokenParam

} // end HttpBaseService
// -----------------------------------------------------------------/------------------------------------------------------------------/
