// -----------------------------------------------------------------/------------------------------------------------------------------/
// auth service
// -----------------------------------------------------------------/------------------------------------------------------------------/

// modules
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';


//helpers
import { RoutesHelper } from '../../helpers/routes.helper';

// services
import { HttpBaseService } from '../http-base/http-base.service';
import { UserService } from '../user/user.service';
import { SpinnerService } from '../spinner/spinner.service';

// models
import { User } from '../../models/classes/user';
import { ɵangular_packages_platform_browser_platform_browser_e } from '@angular/platform-browser';


// -----------------------------------------------------------------/------------------------------------------------------------------/
@Injectable({
  providedIn: 'root'
})
export class AuthService extends HttpBaseService<any> {

  private _user: User;

  constructor(
    public _http: HttpClient,
    private userService: UserService,
    public navCtrl: NavController,
    public spinner: SpinnerService,
    public router: Router
  ) {
    super(`${environment.nodeServerBaseUrl}/api/AppUsers`, _http);
  } // end constructor

  /**
   * -
   * @param _username {string} is the email of the user
   * @param _password {string}
   * @returns Observable<any> returns a useer
   */
  public login(_username: string, _password: string): Observable<any> {
    return this.post('/login', {
      username: _username,
      password: _password,
      realm: 'app'
    }).pipe(
      switchMap(res => {
        localStorage.setItem('access-token', res['id']);
        localStorage.setItem('access-token-created', res['created']);
        localStorage.setItem('access-token-ttl', res['ttl']);
        let tokenExpires = new Date(res['created']);
        tokenExpires.setSeconds(tokenExpires.getSeconds() + res['ttl']);
        localStorage.setItem('access-token-expires', tokenExpires.toString());
        localStorage.setItem('id', res['userId']);

        return this.userService.getUserWithRoles(res['userId']).pipe(
          switchMap(users => {
            this.setUser = users[0];
            return of(this._user);
          })
        );
      })
    );
  } // end login


  getAccessToken() {
    return localStorage.getItem('access-token');
  }// end getAccessToken


  public forgotPassword(_email): Observable<any> {
    // console.log('em', _email);
    return this.post('/reset', { email: _email })
      .pipe(
        map(_res => {
          // FIX THIS TODAY!!!!!
          console.log('send forgot password request! ', _res);
          // send change password email with generated token.
          return { success: true };
        }),
        catchError(_error => {
          // tslint:disable-next-line:max-line-length
          return throwError({ error: 'Forgot password email did not send! Please check your email address and try again!', status: _error.status });
        })
      );
  } // end forgotPassword

  public resetPasswordFromToken(_newPassword, _resetPasswordAccessToken) {
    // console.log('npw', _newPassword);

    return this.post('/reset-password', { newPassword: _newPassword }, { 'access_token': _resetPasswordAccessToken })
      .pipe(
        map(_res => {

          // console.log('reset password from token request! ', _res);
          // send change password email with generated token.
          return { success: true, response: _res };
        }),
        catchError(_error => {
          // tslint:disable-next-line:max-line-length
          return throwError({ error: 'Invalid or expired reset token! Please send yourself a valid token by navigating to forgot password.', status: _error.status });
        })
      );
  } // end resetPasswordFromToken


  logout() {
    this.spinner.show();
    this.post('/logout', '').subscribe(res => {
      this.navCtrl.navigateRoot('/login');
      localStorage.removeItem('access-token');
      this.spinner.hide();
    });
  } // end logout


  public get user(): User {
    return this._user;
  } // end user

  /**
   * @description Sets the private _user object to the current authenticated user.
   * @param user {User} a user object
   * @returns void
   */
  private set setUser(user: User) {
    this._user = user;
  } // end set user


  public get isUserNurse(): boolean {
    if (!this._user) return false;
    else return this._user.roles.some(role => role.name === 'nurse');
  } // end get isUserNurse


  public get isUserAdmin(): boolean {
    if (!this._user) return false;
    else return this._user.roles.some(role => role.name === 'admin');
  } // end get isUserAdmin


  public get isUserPhysician(): boolean {
    if (!this._user) return false;
    else return this._user.roles.some(role => role.name === 'physician');
  } // end get isUserPhysician

  public get tryLoginAutomatically$(): Observable<boolean> {
    try {
      const accessToken = localStorage.getItem('access-token');
      const userId = localStorage.getItem('id');

      if (!accessToken || !userId) return of(false);

      return this.userService.getUserWithRoles(userId).pipe(
        switchMap(users => {
          // this._user = users[0];
          this.setUser = users[0];
          return of(true);
        }),
        catchError(err => {
          return of(false);
        })
      );
    }
    catch (err) {
      return of(err);
    }
  } // end tryLoginAutomatically$


  public navigateUserToRootPageByRole(): Promise<boolean> {
    var destinationRoute = RoutesHelper.LOGIN;
    try {
      if (this._user) {
        if (this.isUserAdmin) destinationRoute = RoutesHelper.USER_MANAGEMENT;
        if (this.isUserNurse) destinationRoute = RoutesHelper.NURSE_HOME;
        if (this.isUserPhysician) destinationRoute = RoutesHelper.PHYSICIAN_ASSESSMENTS;
      }

      return this.navCtrl.navigateRoot(destinationRoute);

    }
    catch (err) {
      console.log('Error in root navigation', err);
    }
    return this.navCtrl.navigateRoot(destinationRoute);
  } // end navigateUserToRootPageByRole

  public getIdAndParamsFromHash(hash) {
    let params: any = {};
    if (hash && hash.length > 0 && hash.indexOf('/')) {
      let firstIndexOfParams = hash.indexOf('?');
      let indexOfLastForwardSlash = hash.lastIndexOf('/');
      let hashSubstring = hash.substring(indexOfLastForwardSlash + 1);
      params.id = hashSubstring.split('?')[0];
      if (firstIndexOfParams !== -1) {
        while (hashSubstring && hashSubstring.length > 0) {
          let paramString = hashSubstring.split('?').pop().split('&')[0];
          if (!paramString || paramString.length === 0) {
            paramString = hashSubstring.split('&').pop().split('&')[0];
          }
          if (paramString && paramString.length > 0) {
            let paramArray = paramString.split('=');
            if (paramArray && paramArray.length > 1) {
              params[paramArray[0]] = paramArray[1];
            }
          }
          hashSubstring = hashSubstring.replace(paramString, '');
          if (hashSubstring.indexOf('=') === -1) {
            break;
          }
        }
      }
    }
    return params;
  }

  public getRouteFromHash(hash) {
    let route = '';
    if (hash && hash.length > 0 && hash.indexOf('/')) {
      let firstIndexOfForwardSlash = hash.indexOf('/');
      let indexOfLastForwardSlash = hash.lastIndexOf('/');
      route = hash.substring(firstIndexOfForwardSlash, indexOfLastForwardSlash + 1);
    }
    return route;
  }

  public get isAuthenticated(): boolean {
    this.refreshAccessToken();
    return (this._user && localStorage.getItem('access-token-expires') && new Date(localStorage.getItem('access-token-expires')) > new Date());
  } // end get isAuthenticated

  refreshAccessToken() {
    let accessToken = this.getAccessToken();
    let now = new Date();
    let nowMinusTenMinutes = new Date()
    if (accessToken && localStorage.getItem('access-token-expires')) {
      let tokenExpiration = new Date(localStorage.getItem('access-token-expires'));
      let tokenExpirationMinusTenMinutes = new Date(localStorage.getItem('access-token-expires'));
      tokenExpirationMinusTenMinutes.setMinutes(tokenExpirationMinusTenMinutes.getMinutes() - 10);
      //If token is not expired but is within 10 minutes of expiring fetch a new one using the current one
      if (tokenExpiration > now && tokenExpirationMinusTenMinutes < now) {
        this.getNewAccessToken();
      }
    }
  }

  getNewAccessToken() {
    let loginByTokenSubscription = this.get('/loginByToken').subscribe((res) => {
      localStorage.setItem('access-token', res['id']);
      localStorage.setItem('access-token-created', res['created']);
      localStorage.setItem('access-token-ttl', res['ttl']);
      let tokenExpires = new Date(res['created']);
      tokenExpires.setSeconds(tokenExpires.getSeconds() + res['ttl']);
      localStorage.setItem('access-token-expires', tokenExpires.toString());
      localStorage.setItem('id', res['userId']);
    },err => {      
    },()=>{
      loginByTokenSubscription.unsubscribe();
    });
  }

} // end AuthService
// -----------------------------------------------------------------/------------------------------------------------------------------/
