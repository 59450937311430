// -----------------------------------------------------------------/------------------------------------------------------------------/
// base model
// -----------------------------------------------------------------/------------------------------------------------------------------/

import { IBase } from '../interfaces/ibase';

export class Base implements IBase {

  id: number;
  isActive: boolean | number;
  status: string;
  dateCreated: Date;
  createdBy: string;
  dateUpdated: Date;
  updatedBy: string;
  dateDeleted: Date;
  deletedBy: string;

  constructor() { } // end constructor

  _onCreate(isActive: boolean, createdBy: string): this {
    this.isActive = isActive;
    this.createdBy = createdBy;
    this.dateCreated = new Date();

    return this;
  } // end onCreate

  _onUpdate(updatedBy: string): this {
    this.updatedBy = updatedBy;
    this.dateUpdated = new Date();

    return this;
  } // end onUpdate

  _onDelete(deletedBy: string): this {
    this.deletedBy = deletedBy;
    this.dateDeleted = new Date();

    return this;
  } // end onDelete

} // end Base
// -----------------------------------------------------------------/------------------------------------------------------------------/
