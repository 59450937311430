// -----------------------------------------------------------------/------------------------------------------------------------------/
// entity type service
// -----------------------------------------------------------------/------------------------------------------------------------------/

// modules
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// environments
import { environment } from 'src/environments/environment';

// services
import { HttpBaseService } from '../http-base/http-base.service';

// models
import { EntityType } from 'src/app/models/classes/entity-type';

@Injectable({
  providedIn: 'root'
})
export class EntityTypeService extends HttpBaseService<EntityType> {

  constructor(private _http: HttpClient) { 
    super(`${environment.nodeServerBaseUrl}/api/AppUsers`, _http);
  } // end constructor
  
} // end EntityTypeService
// -----------------------------------------------------------------/------------------------------------------------------------------/