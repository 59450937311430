 import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: 'home', loadChildren: './pages/home/home.module#HomePageModule' },
  // * auth pages
  { path: 'login', loadChildren: './pages/auth/login/login.module#LoginPageModule'  },
  { path: 'forgot-password', loadChildren: './pages/auth/forgot-password/forgot-password.module#ForgotPasswordPageModule' },
  { path: 'reset-password', loadChildren: './pages/auth/reset-password/reset-password.module#ResetPasswordPageModule' },
  { path: 'reset-password-confirmation', loadChildren: './pages/auth/reset-password-confirmation/reset-password-confirmation.module#ResetPasswordConfirmationPageModule' },
  { path: 'forgot-password-confirmation', loadChildren: './pages/auth/forgot-password-confirmation/forgot-password-confirmation.module#ForgotPasswordConfirmationPageModule' },

  { path: 'user-management', loadChildren: './pages/admin/user-management/user-management.module#UserManagementPageModule', canActivate: [AuthGuardService] },
  // { path: 'assessment', loadChildren: './assessment/assessment.module#AssessmentPageModule' },
  { path: 'nurse-assessments', loadChildren: './pages/nurse/nurse-assessments/nurse-assessments.module#NurseAssessmentsPageModule', canActivate: [AuthGuardService] },
  // tslint:disable-next-line:max-line-length
  { path: 'physician-assessments', loadChildren: './pages/physician/physician-assessments/physician-assessments.module#PhysicianAssessmentsPageModule', canActivate: [AuthGuardService] },
  { path: 'admin-assessments', loadChildren: './pages/admin/admin-assessments/admin-assessments.module#AdminAssessmentsPageModule' },
  { path: 'nurse-home', loadChildren: './pages/nurse/nurse-home/nurse-home.module#NurseHomePageModule' },
  { path: 'completed-assessment/:id', loadChildren: './pages/assessment/completed-assessment/completed-assessment.module#CompletedAssessmentPageModule' },
  { path: 'assessment-details/:id', loadChildren: './pages/assessment/assessment-details/assessment-details.module#AssessmentDetailsPageModule' },
  
  // * new assessment pages
  { path: 'new-assessment/:id', loadChildren: './pages/assessment/new-assessment/root-assessment/root-assessment.module#RootAssessmentPageModule' },
  // * cid = child assessment Id
  { path: 'new-assessment/:id/assessment/:cid', loadChildren: './pages/assessment/new-assessment/child-assessment/child-assessment.module#ChildAssessmentPageModule' },
  { path: 'new-assessment/:id/preview', loadChildren: './pages/assessment/new-assessment/preview/preview.module#PreviewPageModule' },
  { path: 'new-assessment/:id/send', loadChildren: './pages/assessment/new-assessment/send-physician/send-physician.module#SendPhysicianPageModule' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
