// -----------------------------------------------------------------/------------------------------------------------------------------/
// question option selected model
// -----------------------------------------------------------------/------------------------------------------------------------------/

// models
import { Base } from './base';
import { IQuestionOptionSelected } from '../interfaces/iquestion-option-selected';

export class QuestionOptionSelected extends Base implements IQuestionOptionSelected {

  assessmentId: number;
  questionId: number;
  questionOptionId: number;
  questionOptionScaleOptionId: number;
  text: string;
  isChecked: boolean;
  fieldName: string;

  constructor() {
    super();
  } // end constructor

  /**
   * 
   * @param values {object} an object with the minimum required values needed to have a valid questionOptionSelected record
   *
   */
  static onCreate(values: {
    questionId: number;
    assessmentId: number;
    isActive: boolean;
    createdBy: string;
    text: string;
    isChecked: boolean;
    questionOptionId: number;
    questionOptionScaleOptionId: number,
    fieldName: string
  }): QuestionOptionSelected {

    const model = new QuestionOptionSelected()._onCreate(values.isActive, values.createdBy);

    model.questionId = values.questionId;
    model.assessmentId = values.assessmentId;

    if (values.text) model.text = values.text;
    if (values.isChecked !== null && values.isChecked !== undefined) model.isChecked = values.isChecked;
    if (values.questionOptionId) model.questionOptionId = values.questionOptionId;
    if (values.questionOptionScaleOptionId) model.questionOptionScaleOptionId = values.questionOptionScaleOptionId;
    if (values.fieldName) model.fieldName = values.fieldName;

    return model;
  } // end onCreate


  /**
   *
   * @param id {string} id of the QuestionOptionSelected
   * @param updatedBy {string} provide the id of the user updating the questionOptionSelected
   * @param text? {string} provide a text answer if the Question is an input or textarea if not, provide null
   * @param isChecked? {boolean} provide a isChecked answer if the Question is an checkbox if not, provide null
   * @param questionOptionId? {string} provide the QuestionOptionId answer  if the Question is an radiobutton or dropdown if not, provide null
   * @param questionOptionScaleOptionId? {string} provide the QuestionOptionId answer if the Question is an scale or range if not, provide null
   */
  static onUpdate(values: {
    id: number,
    updatedBy: string,
    text?: string,
    isChecked?: boolean,
    questionOptionId?: number,
    questionOptionScaleOptionId?: number,
    fieldName?: string
  }): QuestionOptionSelected {

    const model = new QuestionOptionSelected()._onUpdate(values.updatedBy);
    model.id = values.id;

    if (values.text !== null || values.text !== undefined) model.text = values.text;
    if (values.isChecked !== null && values.isChecked !== undefined) model.isChecked = values.isChecked;
    if (values.questionOptionId) model.questionOptionId = values.questionOptionId;
    if (values.questionOptionScaleOptionId) model.questionOptionScaleOptionId = values.questionOptionScaleOptionId;
    if (values.fieldName) model.fieldName = values.fieldName;

    return model;
  } // end onUpdate

} // end QuestionOptionSelected
// -----------------------------------------------------------------/------------------------------------------------------------------/
