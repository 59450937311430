//modules
import { Router, NavigationEnd } from "@angular/router";
import { Injectable } from "@angular/core";
import { filter } from "rxjs/operators";

@Injectable()
export class RoutingStateService {
    private history: any = [];

    constructor(
        private router: Router
    ) { }

    loadRouting(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
                this.history = [...this.history, urlAfterRedirects];
            });
    }

    getHistory(): string[] {
        return this.history;
    }

    getPreviousUrl(): string {
        return this.history[this.history.length - 2] || '/';
    }
}