// -----------------------------------------------------------------/------------------------------------------------------------------/
// assessment service
// -----------------------------------------------------------------/------------------------------------------------------------------/

// modules
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

// services
import { HttpBaseService } from '../http-base/http-base.service';
import { AssessmentTypeService } from '../assessmentType/assessment-type.service';

// models
import { Assessment } from '../../models/classes/assessment';
import { AssessmentType } from '../../models/classes/assessment-type';
import { ClusterResult } from 'src/app/models/classes/clusterResult';

@Injectable({
  providedIn: 'root'
})
export class AssessmentService extends HttpBaseService<Assessment>  {

  private _currentAssessment: BehaviorSubject<Assessment>;

  constructor(
    public _http: HttpClient,
    private assessmentTypeService: AssessmentTypeService
  ) {
    super(`${environment.nodeServerBaseUrl}/api/Assessments`, _http);
    this._currentAssessment = new BehaviorSubject(null);
  } // end constructor

  // -----------------------------------------------------------------/------------------------------------------------------------------/
  // accessors/mutators
  // -----------------------------------------------------------------/------------------------------------------------------------------/

  /**
  * @returns Observable<Assessment> observable containing the current assessment that is being fill out
  */
  get currentAssessment$(): Observable<Assessment> {
    return this._currentAssessment.asObservable();
  } // end get currentAssessment


  /**
  * @returns Assessment value containing the current assessment that is being fill out
  */
  get currentAssessment(): Assessment | null {
    return this._currentAssessment.value;
  } // end get currentAssessment


  set newAssessment(value: Assessment) {
    console.log('value', value);
    this._currentAssessment = new BehaviorSubject(value);
  } // end set newAssessment


  setCurrentAssessment(assessment: Assessment) {
    this._currentAssessment.next(assessment);
  } // end setCurrentAssessment

  // -----------------------------------------------------------------/------------------------------------------------------------------/
  // api methods
  // -----------------------------------------------------------------/------------------------------------------------------------------/

  /**
   * @description get a list of completed assessments by nurse
   * @param nurseId {string} the nurse id that has completed assessments
   * @returns Observable<Assessment[]>
   */
  completedAssessmentsByNurseId$(nurseId: string): Observable<Assessment[]> {
    const params = { 'filter': JSON.stringify({ 'where': { 'nurseId': nurseId, 'status': 'completed' }, 'include': ['physician'] }) };

    return this.get<Assessment>('', params);
  } // end completedAssessmentsByNurseId

  /**
   * @description get a list of completed assessments by physician
   * @param physicianId {string} the physician id that has completed assessments
   * @returns Observable<Assessment[]>
   */
  completedAssessmentsByPhysicianId$(physicianId: string): Observable<Assessment[]> {
    const params = { 'filter': JSON.stringify({ 'where': { 'physicianId': physicianId, 'status': 'completed' }, 'include': ['nurse'] }) };

    return this.get<Assessment>('', params);
  } // end completedAssessmentsByPhysicianId


  // /**
  //  * @description gets a specific assessment based on the id specified
  //  * @param id {string} assessment id 
  //  */
  // getAssessmentWithAssessmentTypeQuestions(id: number): Observable<Assessment> {
  //   const params = { 'filter': JSON.stringify({ 'include': ['questionOptionSelecteds'] }) };

  //   return this.get(`/${id}/assessmentTypeWithQuestions`).pipe(tap(res => this.setCurrentAssessment(res)));
  // } // end getAssessment


  /**
   * @description gets all information of the assessmnent such as assessment type, sections, questions, questionOptionSelecteds, etc
   * @param id {string} assessment id 
   */
  getAllAssessmentInfo(id: string): Observable<Assessment> {
    return this.get(`/${id}/all-info`);
  } // end getAssessment


  /**
    *
    * @param nurseId {number} the id of the nurse conducting the assessment
    * @param createdBy {string} the id of the user creating the assessment
    */
  createAssessment(nurseId: number): Observable<{ assessment: Assessment }> {
    return this.post('/new', { nurseId: nurseId });
  } // end createAssessment


  /**
  *
  * @param nurseId {number} the id of the nurse conducting the assessment
  * @param assessmentTypeId  {number} the assessment type id
  * @param status {string} the status could be 'in_progress' or 'completed'
  * @param createdBy {string} the id of the user creating the assessment
  * @returns Observable<Assessment>
  */
  create(nurseId: number, assessmentTypeId: number, status: string, createdBy: string): Observable<Assessment> {
    const assessment = Assessment.onCreate(nurseId, assessmentTypeId, status, createdBy);

    return this.post('', assessment);
  } // end get create


  /**
    * @param assessment  {Assessment} the assessment
    * @returns Observable<any>
    */
  update(id: string, field: any): Observable<any> {
    return this.patch(`/${id}`, field);
  } // end get create


  /**
   * @description it gets the child assessments based on the answers the user selected on the root assessment
   * @param obj { parentAssessmentId: string, assessmentId: string } it could include either parentAssessmentId (or root assessment id)
   *  or the assessmentId (or child assesment)
   * @returns Observable<{assessments: Assessments[]}> the child assessments that the nurse will fill out
   */
  getChildAssessments(obj: { parentAssessmentId: string, assessmentId: string }): Observable<{ assessments: Assessment[] }> {
    return this.post('/child-assessments', obj);
  } // end getChildAssessments

  /**
   * @description It sends the asssessment to the physician and marks the assessment as completed
   * @returns Observable<any>
   */
  sendToPhysician(assessmentId: number, physicianId: number): Observable<any> {
    return this.post('/send-assessment', { assessmentId, physicianId });
  } // end sendToPhysician


  /**
   * @description it gets all of the child assessments of a root assessment
   * @param assessmentId the root assessmentId
   * @returns Observable<{assessments: Assessment[]}>
   */
  getChildrenOfCompletedAssessment(assessmentId): Observable<Assessment[]> {
    const params = { 'filter': JSON.stringify({ 'where': { 'parentAssessmentId': assessmentId } }) };
    return this.get('', params).pipe(switchMap(assessments => combineLatest(assessments.map(assessment => this.getAllAssessmentInfo(assessment.id)))));
  }// end getChildrenOfCompletedAssessment


  /**
   * @description it gets all of the clusterResults met by the assessment
   * @param id assessmentId
   * @returns Observable<{clusterResults: ClusterResult[]} 
   */
  getClusterResults(id: number): Observable<{ clusterResults: ClusterResult[] }> {
    return this.get(`/${id}/score`);
  }// end getClusterResults


  /**
   * @description it gets a URL needed to export assessment data
   * 
   * @param startDate {Date} 
   * @param endDate {Date}
   */
  getExportDataURL(startDate?: Date, endDate?: Date) {
    const startDateParam = startDate ? 'startDate=' + encodeURIComponent(startDate.toString()) : '',
      endDateParam = endDate ? 'endDate=' + encodeURIComponent(endDate.toString()) : '';

      return `${environment.nodeServerBaseUrl}/api/assessments/exportData?access_token=${localStorage.getItem('access-token')}&${startDateParam}&${endDateParam}`;
  } // end getExportDataURL


  /**
   * @description it gets a URL needed to export assessment data
   * 
   * @param startDate {Date} 
   * @param endDate {Date}
   */
  getMessageExportDataURL(startDate?: Date, endDate?: Date) {
    const startDateParam = startDate ? 'startDate=' + encodeURIComponent(startDate.toString()) : '',
      endDateParam = endDate ? 'endDate=' + encodeURIComponent(endDate.toString()) : '';

      return `${environment.nodeServerBaseUrl}/api/assessments/exportMessageData?access_token=${localStorage.getItem('access-token')}&${startDateParam}&${endDateParam}`;
  } // end getExportDataURL

  // -----------------------------------------------------------------/------------------------------------------------------------------/
  // custom methods
  // -----------------------------------------------------------------/------------------------------------------------------------------/

  /**
   * sets the current assessment to null
   */
  resetCurrentAssessment() {
    this._currentAssessment.next(null);
  } // end resetCurrentAssessment

} // end AssessmentService
// -----------------------------------------------------------------/------------------------------------------------------------------/
