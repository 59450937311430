// -----------------------------------------------------------------/------------------------------------------------------------------/
// message entry log service
// -----------------------------------------------------------------/------------------------------------------------------------------/

// modules
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// environments
import { environment } from 'src/environments/environment';

// services
import { HttpBaseService } from '../http-base/http-base.service';

// models
import { MessageEntryLog } from 'src/app/models/classes/message-entry-log';

@Injectable({
  providedIn: 'root'
})
export class MessageEntryLogService extends HttpBaseService<MessageEntryLog> {

  constructor(
    public _http: HttpClient,
  ) {
    super(`${environment.nodeServerBaseUrl}/api/MessageEntryLogs`, _http);
  } // end constructor


  /**
    * @param assessment  {Assessment} the assessment
    * @returns Observable<any>
    */
  update(id: string, field: any): Observable<any> {
    return this.patch(`/${id}`, field);
  } // end get create


  updateMultiple(condition: string, data: any) {
    const params = `where=${encodeURIComponent(condition)}&&`;

    return this.post('/update?' + params, data);
  } // end updateMultiple

} // end MessageEntryLogService
// -----------------------------------------------------------------/------------------------------------------------------------------/