// -------------------------------------------------------------------------/-------------------------------------------------------------------------/
// app component
// -------------------------------------------------------------------------/-------------------------------------------------------------------------/

// modules
import { Component, ViewEncapsulation } from '@angular/core';
import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ActivatedRoute, Router } from '@angular/router';
import { URLSearchParams } from '@angular/http';

// helpers
import { RoutesHelper } from './helpers/routes.helper';

// services
import { SpinnerService } from './services/spinner/spinner.service';
import { AuthService } from './services/auth/auth.service';
import { Subscription } from 'rxjs';
import { RoutingStateService } from './services/routing/routing-state-service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {

  routerSubscription: Subscription;
  returnUrl: string = '';

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public spinnerService: SpinnerService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private routingStateService: RoutingStateService
  ) {
    this.initializeApp();
    this.routingStateService.loadRouting();
  } // end constructor

  initializeApp() {
    this.onReady();
    this.onResume();
  } // end initializeApp


  onReady() {
    this.platform.ready().then(async () => {
      // Get the query params
      this.route.queryParams
        .subscribe(params =>{
         this.returnUrl = params['return'];
        });
      if (!this.router.getCurrentNavigation() || !this.router.getCurrentNavigation().extractedUrl.queryParams['access_token']) { // check for access_token for password reset
        this.checkUserLoggedIn();
      }
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  } // end onReady

  onResume() {
    this.platform.resume.subscribe(() => {
      this.checkUserLoggedIn();
    });
  } // end onResume

  checkUserLoggedIn() {
    this.spinnerService.show();
    this.authService.tryLoginAutomatically$.subscribe(
      success => {
        if(window.location && window.location.hash && window.location.hash.indexOf('reset-password?') > -1) return this.router.navigateByUrl(window.location.hash.replace('#','')); 
        if (!success) return this.router.navigate([RoutesHelper.LOGIN], { queryParams: { returnUrl: window.location.hash } });
        var destinationRoute = RoutesHelper.LOGIN;
        if (this.authService.isUserAdmin) destinationRoute = RoutesHelper.USER_MANAGEMENT;
        if (this.authService.isUserNurse) destinationRoute = RoutesHelper.NURSE_HOME;
        if (this.authService.isUserPhysician) destinationRoute = RoutesHelper.PHYSICIAN_ASSESSMENTS;
        if (this.returnUrl && this.returnUrl.length > 2 && this.returnUrl.indexOf('login') === -1) {
          return this.router.navigateByUrl(this.returnUrl);
        };
        //Need this as well since the route and router doesnt see the hash until after this has run
        if (window.location.hash && window.location.hash.length > 2 && window.location.hash.indexOf('login') === -1) return this.router.navigateByUrl(window.location.hash.replace('#',''));     
        
        this.router.navigate([destinationRoute]);
        // console.log('check user:', this.authService.user);
      },
      err => {
        console.log('Error in checkUserLoggedIn: ', err);
        this.router.navigate([RoutesHelper.LOGIN])
      },
      () => this.spinnerService.hide(),
    );
  } // end checkUserLoggedIn

} // end AppComponent
// -------------------------------------------------------------------------/-------------------------------------------------------------------------/