// -----------------------------------------------------------------/------------------------------------------------------------------/
// http base service
// -----------------------------------------------------------------/------------------------------------------------------------------/

// modules
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

// services
import { HttpBaseService } from '../http-base/http-base.service';
import { AssessmentService } from '../assessment/assessment.service';
import { AuthService } from '../auth/auth.service';

// helpers
import { QuestionTypesHelper } from 'src/app/helpers/questionTypes.helper';

// models
import { QuestionOptionSelected } from '../../models/classes/question-option-selected';
import { Assessment } from 'src/app/models/classes/assessment';
import { QuestionOptionSelectedResponse } from 'src/app/models/classes/question-option-selected-response';

@Injectable({
  providedIn: 'root'
})
export class QuestionOptionSelectedService extends HttpBaseService<QuestionOptionSelected> {

  constructor(
    public _http: HttpClient,
    private assessmentService: AssessmentService,
    private authService: AuthService
  ) {
    super(`${environment.nodeServerBaseUrl}/api/QuestionOptionSelecteds`, _http);
  } // end constructor

  // -----------------------------------------------------------------/------------------------------------------------------------------/
  // api methods
  // -----------------------------------------------------------------/------------------------------------------------------------------/
  /**
   * 
   * @param questionId {number} question id that relates to the answer to be saved
   * @param answer {any} the answer for the current question, which it could be a boolean, number, string, etc.
   * @param type {string} the type of question being answered, i.e, checkbox, input_text, input_number, datetime, etc.
   * @param assessmentId {number} assessment id
   * @param fieldName {string} name of the field, it could be baseline, vital_signs, etc.
   */
  create(questionId: number, answer: any, type: string, assessmentId: number, fieldName?: string): Observable<QuestionOptionSelectedResponse> {
    const answerValues = this._getAnswerValues(answer, type);

    const model = QuestionOptionSelected.onCreate({
      questionId,
      assessmentId,
      isActive: true,
      createdBy: this.authService.user.id.toString(),
      text: answerValues.text,
      isChecked: answerValues.isChecked,
      questionOptionId: answerValues.questionOptionId,
      questionOptionScaleOptionId: answerValues.questionOptionScaleOptionId,
      fieldName: fieldName
    });

    return this.post('', model);
  } // end create


  /**
   *
   * @param questionOptionSelectedId {number} id of the question
   * @param answer {any} the answer for the current question, which it could be a boolean, number, string, etc.
   * @param type {string} the type of question being answered, i.e, checkbox, input_text, input_number, datetime, etc.
   * @param assessmentId {number} assessment id
   * @param fieldName {string} name of the field, it could be baseline, vital_signs, etc.
   */
  update(questionOptionSelectedId: number, answer: any, type: string, assessmentId: number, fieldName?: string): Observable<QuestionOptionSelectedResponse> {
    const answerValues = this._getAnswerValues(answer, type);

    const model = QuestionOptionSelected.onUpdate({
      id: questionOptionSelectedId,
      updatedBy: this.authService.user.id.toString(),
      text: answerValues.text,
      isChecked: answerValues.isChecked,
      questionOptionId: answerValues.questionOptionId,
      questionOptionScaleOptionId: answerValues.questionOptionScaleOptionId,
      fieldName
    });

    return this.patch(`/${questionOptionSelectedId.toString()}`, model);
  } // end update


  /**
   * @description it deletes a QuestionOptionSelected and returns a custom object
   * @param id {number} QuestionOptionSelected id
   */
  customDeleteById(id: number) {
    return this.delete(`/${id}/answer`);
  } // end customDeleteById


  // -----------------------------------------------------------------/------------------------------------------------------------------/
  // accessors/mutators
  // -----------------------------------------------------------------/------------------------------------------------------------------/

  get questionTypes(): any {
    return QuestionTypesHelper.QUESTION_TYPES;
  } // get questionTypes


  // -----------------------------------------------------------------/------------------------------------------------------------------/
  // helper methods
  // -----------------------------------------------------------------/------------------------------------------------------------------/

  private _getAnswerValues(answer: any, type: string): { text?: string, isChecked?: boolean, questionOptionId?: number, questionOptionScaleOptionId?: number } {
    if (this.questionTypes.historyDiagnoses === type) {
      return (typeof answer === 'boolean') ? { isChecked: answer } : { text: answer };
    }

    return {
      text: this.questionTypes.checkbox !== type && this.questionTypes.dropdown !== type &&
        this.questionTypes.radio !== type && this.questionTypes.range !== type ? answer : null,

      isChecked: this.questionTypes.checkbox === type ? answer : null,
      questionOptionId: this.questionTypes.dropdown === type || this.questionTypes.radio === type ? answer : null,
      questionOptionScaleOptionId: this.questionTypes.range === type ? answer : null,
    };

  } // end _getAnswerValues

} // end QuestionOptionSelectedService
// -----------------------------------------------------------------/------------------------------------------------------------------/
