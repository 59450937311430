// -----------------------------------------------------------------/------------------------------------------------------------------/
//  app module
// -----------------------------------------------------------------/------------------------------------------------------------------/

// modules
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClientModule } from '@angular/common/http';
import { BrMaskerModule } from 'br-mask';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

// components
import { AppComponent } from './app.component';

// services
import { UserService } from './services/user/user.service';
import { SpinnerService } from './services/spinner/spinner.service';
import { AssessmentService } from './services/assessment/assessment.service';
import { AssessmentTypeService } from './services/assessmentType/assessment-type.service';
import { QuestionOptionSelectedService } from './services/questionOptionSelected/question-option-selected.service';
import { EntityTypeService } from './services/entityType/entity-type.service';
import { MessageEntryLogService } from './services/messageEntryLog/message-entry-log.service';
import { AuthGuardService } from './services/auth/auth.guard';

// environments
import { environment } from 'src/environments/environment';
import { ToolbarBackButtonComponent } from './components/shared/toolbar-back-button/toolbar-back-button.component';
import { RoutingStateService } from './services/routing/routing-state-service';

const config: SocketIoConfig = { url: environment.nodeServerBaseUrl, options: {transports:['websocket']} };



// -----------------------------------------------------------------/------------------------------------------------------------------/
@NgModule({
  declarations: [
    AppComponent,        
  ],
  entryComponents: [],
  imports: [
    HttpClientModule,
    BrowserModule,
    IonicModule.forRoot({mode:'md'}),
    SocketIoModule.forRoot(config),
    AppRoutingModule,
    NgxSpinnerModule,
    HttpClientModule,
    BrMaskerModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    UserService,
    SpinnerService,
    AssessmentService,
    AssessmentTypeService,
    QuestionOptionSelectedService,
    EntityTypeService,
    MessageEntryLogService,
    AuthGuardService,
    RoutingStateService
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
// -----------------------------------------------------------------/------------------------------------------------------------------/

